import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '~layouts/layout';
import { RenderedContext } from '~layouts/layout';
import Header from '~2-components/Header/Header';
import PageSection from '~2-components/PageSection/PageSection';
import formatPhoneNumber from '~utils/formatPhoneNumber';
import GatsbyImage from 'gatsby-image';
import RichText from '~3-data-components/RichText';
import Helmet from 'react-helmet';
import useSiteMetadata from '~3-data-components/useSiteMetaData';
import ArticleAuthor from '~2-components/ArticleAuthor/ArticleAuthor';
import NewsListKontent from '~3-data-components/NewsListKontent';
import redirectTo from '~/utils/redirect';

const GENERICAUTHORCODENAME = 'adviceline';

export const query = graphql`
    query($id: String!) {
        kontentItemNewsArticle(id: { eq: $id }) {
            system {
                id
                type
                name
            }
            elements {
                author {
                    value {
                        id
                        system {
                            id
                            codename
                        }
                        ... on kontent_item_profile {
                            elements {
                                first_name {
                                    value
                                }
                                last_name {
                                    value
                                }
                                position {
                                    value
                                }
                                phone {
                                    value
                                }
                                email {
                                    value
                                }
                                thumbnail_image {
                                    value {
                                        fluid(maxWidth: 185) {
                                            ...KontentAssetFluid_withWebp
                                        }
                                        name
                                        description
                                    }
                                }
                                page_url {
                                    value
                                }
                            }
                        }
                    }
                }
                date {
                    value(formatString: "D MMM YYYY")
                }
                page_metadata__share_description {
                    value
                }
                page_metadata__share_image {
                    value {
                        url
                    }
                }
                page_metadata__share_title {
                    value
                }
                page_metadata__twitter_creator {
                    value
                }
                page_url {
                    value
                }
                client_side_redirection__redirect_url {
                    value
                }
                practice_areas {
                    value {
                        system {
                            id
                            type
                        }
                    }
                }
                related_news_articles {
                    value {
                        system {
                            id
                            type
                        }
                    }
                }
                rich_text {
                    images {
                        description
                        image_id
                        width
                        height
                        fluid(maxWidth: 994) {
                            ...KontentAssetFluid_withWebp
                        }
                    }
                    modular_content {
                        system {
                            id
                            codename
                            type
                        }
                    }
                    links {
                        codename
                        link_id
                        type
                        url_slug
                    }
                    value
                }
                thumbnail_image {
                    value {
                        description
                        name
                        width
                        url
                        fluid(maxWidth: 991) {
                            ...KontentAssetFluid_withWebp
                        }
                    }
                }
                hero_image {
                    value {
                        description
                        name
                        width
                        fluid(maxWidth: 991) {
                            ...KontentAssetFluid_withWebp
                        }
                    }
                }
                title {
                    value
                }
                add_faq_schema {
                    value {
                        codename
                    }
                }
            }
        }
        allKontentItemSiteSettings {
            nodes {
                elements {
                    homepage {
                        value {
                            ... on kontent_item_general_content {
                                elements {
                                    page_slug {
                                        value
                                    }
                                }
                            }
                        }
                    }
                    news_listing_page {
                        value {
                            ... on kontent_item_general_content {
                                elements {
                                    page_slug {
                                        value
                                    }
                                    title {
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const NewsArticleTemplate = ({ data, location }) => {
    const redirectUrl = data.kontentItemNewsArticle.elements.client_side_redirection__redirect_url.value;
    redirectTo(redirectUrl);

    const pageInfo = data.kontentItemNewsArticle.system;
    const article = data.kontentItemNewsArticle.elements;

    const articleTitle = article.title.value;
    const date = article.date.value;
    const practiceAreas = article.practice_areas.value;
    const manuallySelectedArticles = article.related_news_articles.value;
    const articleUrl = article.page_url.value;

    const siteSettings = data.allKontentItemSiteSettings.nodes[0].elements;
    const homepageSlug = siteSettings.homepage.value.length > 0 ? siteSettings.homepage.value[0].elements.page_slug.value : '';
    const newsListingData = siteSettings.news_listing_page.value[0].elements;
    const breadcrumbs = [{
        title: 'Home',
        pageSlug: homepageSlug,
    },{
        title: newsListingData.title.value,
        pageSlug: newsListingData.page_slug.value,
    },{
        title: articleTitle,
        pageSlug: articleUrl,
        isActive: true,
    }];
    const author = article.author.value[0];
    const genericAuthor = !author || author.system.codename === GENERICAUTHORCODENAME;
    const authorData = author && author.elements;
    const firstName = author ? authorData.first_name.value : '';
    const lastName = author ? authorData.last_name.value : '';
    const fullName = author && `${firstName} ${lastName}`;
    const position = author ? authorData.position.value : '';
    const email = author ? authorData.email.value : '';
    const phone = author ? authorData.phone.value : '';
    const formattedNumber = author ? formatPhoneNumber(phone) : '';
    const authorImage = author && (authorData.thumbnail_image && authorData.thumbnail_image.value.length > 0) ? authorData.thumbnail_image.value[0]: null;
    const authorUrl = author ? authorData.page_url.value : '';
    const primaryImage = article.thumbnail_image.value[0];
    const optionalImage = article.hero_image.value;
    const image = optionalImage.length ? optionalImage[0] : primaryImage;
    const richText = article.rich_text;

    const { site_title, site_description, site_url, default_share_image, twitter_site } = useSiteMetadata();
    const customMetaDescription = article.page_metadata__share_description.value;
    const customMetaImage = article.page_metadata__share_image.value;
    const customMetaTitle = article.page_metadata__share_title.value;
    const metaDataTwitterCreator = article.page_metadata__twitter_creator.value.length ? article.page_metadata__twitter_creator.value : twitter_site.value;
    const pagePath = `${site_url.value}${location.pathname}`;
    const defaultShareImage = default_share_image.length > 0 ? default_share_image.value[0].url : '';
    const metaDataImage = customMetaImage.length ? customMetaImage[0].url : defaultShareImage;
    const metaDataTitle = customMetaTitle.length ? customMetaTitle : articleTitle;
    const metaDataDescription = customMetaDescription.length ? customMetaDescription : site_description.value;

    const addSeoSchema = (article.add_faq_schema && article.add_faq_schema.value[0] && article.add_faq_schema.value[0].codename) === "true";

    const [setArticleBanner] = useState({
        breadcrumbs: breadcrumbs,
        article: true,
        title: articleTitle,
        date: date,
        fullName: fullName,
        pagePath: pagePath,
    });

    let pageContentProps = {};

    if (addSeoSchema) {
        pageContentProps = {
            itemScope: true,
            itemType: "https://schema.org/FAQPage"
        }
    }

    return (
        <Layout>
            <RenderedContext.Provider value={setArticleBanner}>
                <Header />
            </RenderedContext.Provider>

            <Helmet
                title={`${metaDataTitle} | ${site_title.value}`}
            >
                <meta name="description" content={metaDataDescription} />
                <meta property="og:type" content='article' />
                <meta property="og:site_name" content={site_title.value} />
                <meta property="og:url" content={pagePath} />
                <meta property="og:title" content={metaDataTitle} />
                <meta property="og:description" content={metaDataDescription} />
                <meta property="og:image" content={metaDataImage} />
                <meta name="twitter:card" value='summary' />
                <meta name="twitter:site" content={twitter_site.value} />
                <meta name="twitter:creator" content={metaDataTwitterCreator} />
                <meta name="twitter:url" content={pagePath} />
                <meta name="twitter:title" content={metaDataTitle} />
                <meta name="twitter:description" content={metaDataDescription} />
                <meta name="twitter:image" content={metaDataImage} />
                <link rel="canonical" href={pagePath} />
            </Helmet>

            <div className="page-content" {...pageContentProps}>
                <div className="container">
                    <PageSection
                        textLayout="two_columns"
                        rightColContent={<ArticleAuthor
                            cmsImage={ authorImage &&
                                <GatsbyImage
                                    key={authorImage.name}
                                    alt={authorImage.description}
                                    fluid={authorImage.fluid}
                                    style={{width: '100%'}}
                                />
                            }
                            authorName={fullName}
                            formattedPhone={formattedNumber}
                            authorPhone={phone}
                            authorEmail={email}
                            btnUrl={authorUrl}
                            sidebar={true}
                            genericAuthor={genericAuthor}
                        />}
                    >
                        {image &&
                        <GatsbyImage
                            key={image.name}
                            alt={image.description}
                            fluid={image.fluid}
                            style={{ marginBottom: '48px', width: '100%' }}
                        />
                        }
                        <RichText
                            content={richText}
                            images={richText.images}
                            links={richText.links}
                            linkedItems={richText.modular_content}
                        />
                    </PageSection>
                    <ArticleAuthor
                        cmsImage={authorImage &&
                            <GatsbyImage
                                key={authorImage.name}
                                alt={authorImage.description}
                                fluid={authorImage.fluid}
                                style={{width: '100%'}}
                            />
                        }
                        authorName={fullName}
                        authorPosition={position}
                        formattedPhone={formattedNumber}
                        authorPhone={phone}
                        authorEmail={email}
                        btnUrl={authorUrl}
                        genericAuthor={genericAuthor}
                    />
                    <NewsListKontent
                        pageInfo={pageInfo}
                        relatedAreas={practiceAreas}
                        manualArticles={manuallySelectedArticles}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default NewsArticleTemplate;

NewsArticleTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};
